const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://pwj7zvzjt3.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://9nvrypp3w7.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://6sbwlegt3i.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.staging.saas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.saas.forwoodsafety.com',
    WEBSOCKET: 'wss://8hbdhbgchl.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;